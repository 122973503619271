import React from "react";
import styles from "./Order.module.css";
import OrderSuccess from "../Images/OrderSuccess.png";
import Footer from "../Footer/Footer";
import CatlotusNav from "../Navbar/CatlotusNav";
import LowerNavbar from "../LowerNavbar/LowerNavbar";

const Order = () => {
  return (
    <>
      <div className={styles.navBack}>
        <CatlotusNav />
      </div>
      <LowerNavbar />
      <h1 style={{ textAlign: "center" }}>Gracias por su compra! </h1>
      <div className={styles.order}>
        <div className={styles.oderInner}>
          <img src={OrderSuccess} className={styles.OrderSuccess} alt="" />
          <div className={styles.pedido}>¡Pedido realizado con éxito!</div>
          <div className={styles.pedDetail}>
            Estamos preparando tu pedido con todas las normas de segridad. Vas a
            recibir un correo con la lista de cartas e instrucciones para
            proceder
          </div>
        </div>
      </div>
      <p style={{ textAlign: "center" }}>
        A continuación recibirá un correo con los detalles de su compra, junto
        con los pasos siguientes a realizar para que puedas recibir tu pedido.
      </p>
      <Footer />
    </>
  );
};

export default Order;
