import React from 'react'
import Footer from '../Footer/Footer'
import LowerNavbar from '../LowerNavbar/LowerNavbar'
import CatlotusNav from '../Navbar/CatlotusNav'
import styles from './Faq.module.css'

const Faq = () => {
    return (
        <>
        <CatlotusNav/>
        <LowerNavbar/>
        <div className={styles.faq}>
            <div className={styles.hedaing}>¿Cómo comprar?</div>
            <div className={styles.headingDetail}>Para comprar simplemente tienes que buscar las cartas que quieres, con su nombre en inglés, y agregarlas a tu carro. Si no sabes el nombre en ingles, puedes buscarlo en español en <a href="www.scryfall.com" className={styles.underli}>www.scryfall.com</a> 
                 Una vez tengas listo tu carro, selecciona comprar y elige retiro o envío, completando la información de envío para el despacho. Asegúrate de ingresar correctamente la información por que se usará para el contacto y envío.
                Recibirás un correo con el detalle de la compra y las instrucciones para la transferencia. Asegúrate de enviar el comprobante a ventas@catlotus.cl</div>
                <div className={styles.pregHeading}>FAQ’s / Preguntas frecuentes</div>
                <div className={styles.estan}>¿Dónde están ubicados?</div>
                <div className={styles.somoss}>Somos solo tienda online sin ubicación para atender a publico.</div>
                <div className={styles.estan}>¿Cuál es el horario de atención?</div>
                <div className={styles.somoss}>Atendemos de lunes a viernes de 10:00 a 17:00.</div>
                <div className={styles.estan}>¿Bajo qué sistemas entregan?</div>
                <div className={styles.somoss}>Hacemos envios a todo Chile y tenemos retiros en la tienda Piedra Bruja cerca de metro Manuel Montt</div>
                <div className={styles.estan}>¿Cómo funciona el sistema de retiro?</div>
                <div className={styles.somoss}>Una vez que tu pedido está listo para retiro recibirás un correo con un c6digo de 3 digitos que debes presentar en la tienda Piedra Bruja. En el correo tambien esta la direction y horario de retiro. Cualquier persona puede hater retiro del pedido con el número de pedido y el c6digo de confirmación</div>
                <div className={styles.estan}>¿Mediante qué sistema despachan?</div>
                <div className={styles.somoss}>Dentro de la Región Metropolitana usamos un repartidor privado que entrega normalmente el dia hábil siguiente después que llega el correo que el pedido esta listo para envío. Fuera de la Región Metropolitana usamos Chilexpress con despacho dia hábil siguiente</div>
                <div className={styles.estan}>¿Pueden hacer envíos por pagar?</div>
                <div className={styles.somoss}>No. Solo se hacen envíos pagados.</div>
        </div>
        <Footer/>
        </>
    )
}

export default Faq
