import React, { useEffect, useState } from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "./PreviewLair.module.css";
import PreviewLair1 from "../Images/PreviewLair1.png";
import laircard1 from "../Images/laircard1.png";
import Footer from "../Footer/Footer";
import { BASE_URL, serverInstance } from "../../axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { BallTriangle } from "react-loader-spinner";

const PreviewLair = () => {
  const navigate = useNavigate();
  const [radioSelect, setRadioSelect] = useState(1);
  const [dat, setDat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [limit, setLimit] = useState(10);
  const [banner, setBanner] = useState(null);
  const [pageTotal, setPageTotal] = useState(0);
  var meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const radioHandle = () => {
    console.log("radioHandle");
    setRadioSelect(1);
  };
  const radioHandle1 = () => {
    console.log("radioHandle");
    setRadioSelect(2);
  };
  useEffect(() => {
    setLoading(true);

    serverInstance
      .get(`/secret/lair?page=${page}&ffg=${radioSelect}`)
      .then((res) => {
        console.log(res);
        setDat(res.data.values);
        setPageTotal(res.data.totalPages);
        setLoading(false);
        setPage(res.data.page);
        setBanner(res.data?.banner?.link);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, limit, radioSelect]);
  function findSmallestValue(data, property) {
    if (!data || data.length === 0) {
      return null;
    }

    let smallest = data[0][property];

    for (let i = 1; i < data.length; i++) {
      if (data[i][property] < smallest) {
        smallest = data[i][property];
      }
    }

    return smallest;
  }
  function findLargestValue(data, property) {
    if (!data || data.length === 0) {
      return null;
    }

    let smallest = data[0][property];

    for (let i = 1; i < data.length; i++) {
      if (data[i][property] > smallest) {
        smallest = data[i][property];
      }
    }

    return smallest;
  }
  function getUniqueItems(arr) {
    const uniqueItems = [];
    const itemNames = new Set();

    for (const item of arr) {
      if (!itemNames.has(item.name)) {
        itemNames.add(item.name);
        uniqueItems.push(item);
      }
    }

    return uniqueItems;
  }

  return (
    <div>
      <CatlotusNav />
      <LowerNavbar />
      <img
        src={banner}
        className={styles.preciew}
        alt=""
        style={{ marginTop: "-37px", width: "100%" }}
      />
      <div className={styles.preview}>
        <div className={styles.textDr}>
          <div className={styles.line}></div>
          <div className={styles.secro}>Secret Lair</div>
          <div className={styles.line}></div>
        </div>
        <div className={styles.adipi}>
          Acá podrás encontrar las preventas de drops de Secret Lair, junto a
          los productos anteriores que tenemos disponibles.
        </div>
        <div className={styles.slectionButons}>
          <button className={styles.preventa1} onClick={radioHandle}>
            En Preventa
          </button>
          <button className={styles.preventa2} onClick={radioHandle1}>
            Drops anteriores
          </button>
        </div>
        {loading == false ? (
          <>
            <div className={styles.cardArea}>
              {radioSelect === 1 && (
                <>
                  {dat?.map((item) => (
                    <Link to={`/secretliar/${item?.id}`} state={{ condi: "" }}>
                      <div className={styles.card1}>
                        <img
                          src={
                            BASE_URL +
                            "/public" +
                            "/secretlair/" +
                            String(item?.img[0]?.id + item?.img[0]?.ext)
                          }
                          className={styles.laircard1}
                          alt=""
                        />
                        <div className={styles.cardText}>
                          <div className={styles.culture}>{item?.nombre}</div>
                          <div className={styles.prce}>
                            <div className="d-flex justify-content-center flex-wrap">
                              {getUniqueItems(item?.price)?.map((d, inn) => {
                                return (
                                  <div>
                                    {d?.value > 0 && inn < 4 ? (
                                      <span style={{ margin: "0px 4px" }}>
                                        {inn < item?.price?.length - 1 &&
                                        inn > 0
                                          ? " - "
                                          : ""}
                                        ${d?.value?.toLocaleString("es-CL")}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className={styles.blackcolo}>
                          <div className={styles.fin}>Fin de la preventa:</div>
                          <button className={styles.fadeYello}>
                            {item?.end &&
                              meses[new Date(item?.start).getMonth()] +
                                " " +
                                String(new Date(item?.start).getFullYear())}
                          </button>
                        </div>
                      </div>
                    </Link>
                  ))}
                </>
              )}

              {radioSelect === 2 && (
                <>
                  {dat?.map((item) => (
                    <Link
                      to={`/secretliar/${item?.id}`}
                      state={{ condi: "pre" }}
                    >
                      <div className={styles.card1}>
                        <img
                          src={
                            BASE_URL +
                            "/public" +
                            "/secretlair/" +
                            String(item?.img[0]?.id + item?.img[0]?.ext)
                          }
                          className={styles.laircard1}
                          alt=""
                        />
                        <div className={styles.cardText}>
                          <div className={styles.culture}>{item?.nombre}</div>
                          <div className={styles.prce}>
                            <div className="d-flex justify-content-center flex-wrap">
                              {getUniqueItems(item?.price)?.map((d, inn) => {
                                return (
                                  <div>
                                    {d?.value > 0 && inn < 4 ? (
                                      <span style={{ margin: "0px 4px" }}>
                                         {inn < item?.price?.length - 1 &&
                                        inn > 0
                                          ? " - "
                                          : ""}
                                        ${d?.value?.toLocaleString("es-CL")}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className={styles.blackcolo}>
                          <div className={styles.fin}>Fin de la preventa:</div>
                          <button className={styles.fadeYello}>
                            {item?.end &&
                              meses[new Date(item?.start).getMonth()] +
                                " " +
                                String(new Date(item?.start).getFullYear())}
                          </button>
                        </div>
                      </div>
                    </Link>
                  ))}
                </>
              )}
            </div>
            <div
              style={{
                marginTop: "65px",
                display: "flex",
                justifyContent: "center",
                marginBottom: "65px",
              }}
            >
              <Pagination
                count={pageTotal}
                page={page}
                onChange={(e, page) => {
                  setPage(page);
                }}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </>
        ) : (
          <div className={styles.jkl}>
            <BallTriangle
              height={150}
              width={150}
              radius={3}
              color="#4fa94d"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PreviewLair;
