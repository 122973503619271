import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./CardView.module.css";
import CardView1 from "../Images/CardView.png";
import colo1 from "../Images/color1.png";
import Kann from "../Images/Kann.png";
import { GiShoppingCart } from "react-icons/gi";
import foil3 from "../Images/foil3.png";
import viewCat from "../Images/viewCat.png";
import CatlotusNav from "../Navbar/CatlotusNav";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import Footer from "../Footer/Footer";
import { serverInstance } from "../../axiosInstance";
import common from "../Images/rarity/Comun.png";
import mythic from "../Images/rarity/Mitica.png";
import uncommon from "../Images/rarity/Infrecuente.png";
import rare from "../Images/rarity/Rara.png";
import Promocional from "../Images/rarity/Promocional.png";
//foil
import foil from "../Images/search/foil.png";
import etched from "../Images/search/etched.png";
import oversized from "../Images/search/oversized.png";
import oversized_foil from "../Images/search/oversized_foil.png";
import gilded from "../Images/search/gilded.png";
import texturized from "../Images/search/texturized.png";
import surge from "../Images/search/surge.png";
import galaxy from "../Images/search/galaxy.png";
import oil_slick from "../Images/search/oil_slick.png";
import signed from "../Images/search/signed.png";
import step_and_compleat from "../Images/search/step-and-compleat.png";
import halo from "../Images/search/halo.png";
import confetti from "../Images/search/confetti.png";
import double_rainbow from "../Images/search/DOUBLE RAINBOW.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { setCartItem } from "../../Redux/adminReducer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const CardView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state?.AdminReducer?.cartItems);
  const pathname = location.pathname;
  const idd = location?.state;
  console.log(pathname, "pathname", idd);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    scrollToTop();
  }, []);
  const [values, setValues] = useState([]);
  const [detail, setDetail] = useState([]);
  const [state, setState] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [consto, setConsto] = useState([]);
  const [clickedItems, setClickedItems] = useState({});
  const handleDecrement = (q, index) => {
    let arr = [...values];
    if (arr[index] > 0) {
      arr[index] = arr[index] - 1;
      setValues(arr);
      setQuantity((prevCount) => prevCount - 1);
    }
  };

  const handleIncrement = (q, index) => {
    let arr = [...values];
    console.log(q, arr[index]);
    if (q > arr[index]) {
      arr[index] = arr[index] + 1;
      setValues(arr);
    }
  };
  const handleCart = (item, q, index) => {
    let modifiedQ =  item?.quantity >= 1 ? q + 1 : q;
    console.log(
      modifiedQ,
      q,
      index,
      modifiedQ > item.stock || item?.stock == 0
    );
    if (q > 0) {
      if (modifiedQ > item.stock || item?.stock == 0) {
        return Swal.fire("Item is out of stock!", "", "error");
      } else if ( modifiedQ >= 1) {
        handleIncrement(
          item?.stock - item?.reservadas,
          index
        )
        console.log("increase");
        
      }
      let cartIt = [...cartItems];
      cartIt = cartItems.filter((d) => d.idcarta != item.idcarta);
      let cartI = cartItems.filter((d) => d.idcarta === item.idcarta);
      console.log(cartI,"cartI");
      item = {
        ...item,
        quantity: cartI?.length > 0 ? modifiedQ : modifiedQ,
      };
      cartIt.push(item);
      localStorage.setItem("cartItem", JSON.stringify(cartIt));
      dispatch(setCartItem(cartIt));
      let t = 0;
      cartIt.map((d) => (t = t + d.precio * d.quantity));
      setTotal(t);
      // Swal.fire(`${item.nombre} added in cata`, "", "success", 1000);
    }
  };
  // const handleCart = (item, q) => {
  //   if (q > 0) {
  //     let cartIt = [...cartItems];
  //     cartIt = cartItems?.filter((d) => d.idcarta != item.idcarta);
  //     let cartI = cartItems?.filter((d) => d.idcarta === item.idcarta);
  //     item = {
  //       ...item,
  //       quantity: cartI?.length > 0 ? cartI[0]?.quantity + q : q,
  //     };
  //     cartIt.push(item);
  //     localStorage.setItem("cartItem", JSON.stringify(cartIt));
  //     console.log(cartIt, "cartIt");
  //     dispatch(setCartItem(cartIt));
  //     let t = 0;
  //     cartIt.map((d) => (t = t + d.precio * d.quantity));
  //     setTotal(t);
  //     // Swal.fire(`${item.nombre} added in cata`, "", "success", 1000);

  //   }
  // };
  useEffect(() => {
    if (cartItems) {
      let t = 0;
      cartItems?.map((d) => (t = t + d.precio * d.quantity));
      setTotal(t);
    }
  }, []);
  useEffect(() => {
    const pathArray = pathname.split("/");
    serverInstance
      .get(
        `/cartDetail/carta/${pathArray[2]}/${decodeURIComponent(
          pathArray[5] === "single-part" ? pathArray[3] : "multi"
        )}/${pathArray[4] === "null" ? "default" : pathArray[4]
        }?multi=${decodeURIComponent(
          pathArray[5] === "single-part" ? undefined : pathArray[3]
        )}`
      )
      .then((res) => {
        setState(false);
        console.log(res.data);
        setValues(Array.from({ length: res?.data?.cards.length }, () => 1));
        let allCosto = [];
        setDetail(res.data?.cards);
        const imagePromises = [];

        if (res.data?.cards[0]?.mana_cost !== null) {
          res.data?.cards[0]?.mana_cost.forEach((element) => {
            // Clean the element by removing any non-alphanumeric characters except '/'
            const cleanD = element?.replace(/[^A-Za-z0-9/]/g, "");
        
            if (cleanD !== "") {
              // Handle cases with double slashes '//'
              if (cleanD.includes("//")) {
                const parts = cleanD?.split("//").map(part => part.trim());
                parts.forEach((part, partIndex) => {
                  // Remove any '/' characters for image import
                  const formattedMana = part.replace("/", "");
                  const imagePromise = import(`../Images/mana/${formattedMana}.png`).then(
                    (module) => module.default
                  );
                  imagePromises.push(imagePromise);
                  if (partIndex < parts.length - 1) {
                    imagePromises.push("//"); // Add the separator to the imagePromises
                  }
                });
              } else {
                // Handle single mana costs (with or without '/')
                const formattedMana = cleanD.replace("/", "");
                const imagePromise = import(`../Images/mana/${formattedMana}.png`).then(
                  (module) => module.default
                );
                imagePromises.push(imagePromise);
              }
            }
          });
        }

        // Use Promise.all to wait for all images to load
        Promise.all(imagePromises)
          .then((loadedImages) => {
            const allCosto = loadedImages;
            setConsto(allCosto);
            // Now you can use allCosto to render your mana symbols
          })
          .catch((error) => {
            console.error("Failed to load mana images", error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [idd]);

  // let set = "vow";
  // let nombre = "abrade";
  // let cn = "default";
  const totalQuantity = cartItems?.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <div>
      <div className={styles.navBack}>
        <CatlotusNav />
      </div>
      <LowerNavbar />
      {state == false ? (
        <div className={styles.cardMian} style={{ position: "relative" }}>
          {" "}
          <div className={styles.new_cart}>
            <strong>
              <h5 style={{ fontSize: "21px" }}>Carro de compras</h5>
            </strong>
            <div>({totalQuantity} elementos)</div>
            <div>Subtotal: ${Number(total).toLocaleString("es-CL")} </div>
            <hr className={styles.linee} />
            {cartItems?.map((d) => {
              return (
                <>
                  <div>
                    {d.quantity}x{` ` + d?.set + `: ` + d.nombre}
                  </div>
                  <br />
                </>
              );
            })}
            <br />

            <button
              className={styles.ccbutton}
              onClick={() => {
                if (cartItems?.length > 0) {
                  navigate("/cart2");
                }
                console.log("Clicked");
              }}
            >
              Ir al carro
            </button>
          </div>
          <div className={styles.card}>
            <div className={styles.toprad}>
              <div className={styles.title}>{detail[0]?.nombre}</div>
              <div className={styles.description}>
                {detail && detail[0]?.image_uris && < img
                  src={detail[0]?.image_uris[0]?.normal}
                  className={styles.CardViewImage}
                  alt=""
                />}
                <div className={styles.descriptionArea}>
                  <div className={styles.ediicio}>
                    Edición:{" "}
                    <span className={styles.dsescip1}>
                      {" "}
                      {detail[0]?.set_name}
                    </span>
                  </div>
                  <div className={styles.tipu}>
                    Tipo:{" "}
                    <span className={styles.dsescip1}> {detail[0]?.type}</span>
                  </div>
                  <div className={styles.tipu}>
                    Costo:
                    {consto.length > 0 ||
                      consto !== undefined ||
                      consto !== null
                      ? consto?.map((da) => {
                        return da == "//" ? (
                          "//"
                        ) : (
                          <img src={da} className={styles.img1} alt="" />
                        );
                      })
                      : ""}
                  </div>
                  <div className={styles.tipu}>
                    Rareza:
                    {detail[0]?.rarity !== undefined ? (
                      detail[0]?.rarity === "common" ? (
                        <img src={common} className={styles.kann} alt="" />
                      ) : detail[0]?.rarity === "mythic" ? (
                        <img src={mythic} className={styles.kann} alt="" />
                      ) : detail[0]?.rarity === "uncommon" ? (
                        <img src={uncommon} className={styles.kann} alt="" />
                      ) : detail[0]?.rarity === "rare" ? (
                        <img src={rare} className={styles.kann} alt="" />
                      ) : (
                        <img src={Promocional} className={styles.kann} alt="" />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={styles.tipu}>Descripción:</div>
                  <div className={styles.dsescip1}>
                    {detail && detail[0]?.text?.map((i) => i)}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tableArea}>
              <img src={viewCat} className={styles.viewCat} alt="" />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.stockDesi}>Estado</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.stockDesi}>Idioma</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.stockDesi}>Tratamiento foil</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.stockDesi}>Stock</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.stockDesi}>Precio</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.stockDesi}></div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.stockDesi}></div>{" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detail && detail?.map((row, index) => (
                      <TableRow
                        key={row?.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          <div className={styles.num}>
                            {row?.estado == 0
                              ? "NM"
                              : row?.estado == 1
                                ? "SP"
                                : row?.estado == 2
                                  ? "MP"
                                  : row?.estado == 3
                                    ? "HP"
                                    : row?.estado == 4
                                      ? "DMG"
                                      : row?.estado == 5
                                        ? "AL"
                                        : ""}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.idio}>
                            {(row?.idioma).toUpperCase()}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                            {row?.foil >= 1 && row?.foil <= 13 ? (
                              row?.foil === 1 ? (
                                <img style={{ width: "100px" }} src={foil} />
                              ) : row?.foil === 2 ? (
                                <img style={{ width: "100px" }} src={etched} />
                              ) : row?.foil === 3 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={oversized}
                                />
                              ) : row?.foil === 4 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={oversized_foil}
                                />
                              ) : row?.foil === 5 ? (
                                <img style={{ width: "100px" }} src={gilded} />
                              ) : row?.foil === 6 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={texturized}
                                />
                              ) : row?.foil === 7 && row?.signed === 1 ? (
                                <img style={{ width: "100px" }} src={surge} />
                              ) : row?.foil === 8 ? (
                                <img style={{ width: "100px" }} src={galaxy} />
                              ) : row?.foil === 9 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={oil_slick}
                                />
                              ) : row?.foil === 10 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={step_and_compleat}
                                  alt="Step-and-Compleat"
                                />
                              ) : row?.foil === 11 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={halo}
                                  alt="Halo"
                                />
                              ) : row?.foil === 12 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={confetti}
                                  alt="Confetti"
                                />
                              ) : row?.foil === 13 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={double_rainbow}
                                  alt="Double Rainbow"
                                />
                              ) : row?.signed === 1 ? (
                                <img style={{ width: "100px" }} src={signed} />
                              ) : null
                            ) : null}
                          </TableCell>
                        <TableCell align="right">
                          <div className={styles.stok}>
                            {row?.stock - row?.reservadas}
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ fontSize: "21px", fontFamily: "Poppins" }}
                        >
                          {(row?.precio).toLocaleString("es-CL")}
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.numberArea}>
                            <div
                              className={styles.decremet}
                              style={
                                0 == values[index] ? { cursor: "default" } : {}
                              }
                              onClick={() => {

                                handleDecrement(
                                  row?.stock - row?.reservadas,
                                  index
                                )
                              }}
                            >
                              -
                            </div>
                            <div className={styles.numberFielkd}>
                              {values[index]}
                            </div>
                            <div
                              className={styles.increment}
                              style={
                                row?.stock - row?.reservadas == values[index]
                                  ? { cursor: "default" }
                                  : {}
                              }
                              onClick={() =>
                                handleIncrement(
                                  row?.stock - row?.reservadas,
                                  index
                                )
                              }
                            >
                              +
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div
                            className={`${styles.basket} ${clickedItems[index] ? styles.clicked : ''}`}
                            onClick={() => {
                              if (row?.stock == 0) {
                                return Swal.fire("Item is out of stack!", "", "error")
                              } else {
                                setClickedItems((prev) => ({ ...prev, [index]: true }));
                                handleCart(row, values[index],index)
                              }

                            }}
                          >
                            <GiShoppingCart className={styles.shopp} />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <BallTriangle
            height={400}
            width={400}
            radius={2}
            color="#ffcf10"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default CardView;
