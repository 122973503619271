import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import "./Cart.css"
import bander1 from "../Images/bander1.png";
import styles from "./Cart2.module.css";
import { RxCross2 } from "react-icons/rx";
import CatlotusNav from "../Navbar/CatlotusNav";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import Footer from "../Footer/Footer";
import foil from "../Images/search/foil.png";
import etched from "../Images/search/etched.png";
import oversized from "../Images/search/oversized.png";
import oversized_foil from "../Images/search/oversized_foil.png";
import gilded from "../Images/search/gilded.png";
import texturized from "../Images/search/texturized.png";
import surge from "../Images/search/surge.png";
import galaxy from "../Images/search/galaxy.png";
import oil_slick from "../Images/search/oil_slick.png";
import signed from "../Images/search/signed.png";
import step_and_compleat from "../Images/search/step-and-compleat.png";
import halo from "../Images/search/halo.png";
import confetti from "../Images/search/confetti.png";
import double_rainbow from "../Images/search/DOUBLE RAINBOW.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setCartItem,
  setCredit,
  setSecretItem,
} from "../../Redux/adminReducer";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";

const Cart2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state?.AdminReducer?.cartItems);
  const secretItems = useSelector((state) => state?.AdminReducer?.secretItems);
  const creditoo = useSelector((state) => state?.AdminReducer?.creditoo);
  const userI = useSelector((state) => state?.userReducer?.activeUser);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    scrollToTop();
  }, []);
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalSl, setTotalSl] = useState(0);
  const [values, setValues] = useState([]);
  const [active, setActive] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [credito, setCredito] = useState(0);
  const [valuesLair, setValuesLair] = useState([]);
  const [can, setCan] = useState();
  const [final, setFinal] = useState();
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleDecrement = (q, index) => {
    let arr = [...values];
    let cartI = [...cartItems];
    if (arr[index] > 1) {
      arr[index] = arr[index] - 1;

      let qt = cartI[index];
      qt = { ...qt, quantity: values[index] - 1 };
      cartI[index] = qt;
      console.log("before dispatch", cartI);
      localStorage.setItem("cartItem", JSON.stringify(cartI));
      let t = 0;
      cartI.map((d) => (t = t + d.quantity * d.precio));
      setTotal(t);
      dispatch(setCartItem(cartI));
      setValues(arr);
    }
  };
  const handleDecrementLair = (q, index) => {
    let arr = [...valuesLair];
    let cartI = [...secretItems];
    if (arr[index] > 1) {
      arr[index] = arr[index] - 1;

      let qt = cartI[index];
      qt = { ...qt, cantidad: valuesLair[index] - 1 };
      cartI[index] = qt;
      console.log("before dispatch", cartI);
      localStorage.setItem("secretItem", JSON.stringify(cartI));
      let t = 0;
      cartI.map((d) => (t = t + d.quantity * d.precio));
      setTotal(t);
      dispatch(setSecretItem(cartI));
      setValuesLair(arr);
    }
  };
  const handleIncrement = (q, index) => {
    let arr = [...values];
    let cartI = [...cartItems];
    if (q > arr[index]) {
      arr[index] = arr[index] + 1;
      let qt = cartI[index];
      qt = { ...qt, quantity: values[index] + 1 };
      cartI[index] = qt;
      console.log("before dispatch", cartI, qt);
      localStorage.setItem("cartItem", JSON.stringify(cartI));
      dispatch(setCartItem(cartI));
      let t = 0;
      cartI.map((d) => (t = t + d.quantity * d.precio));
      setTotal(t);
      setValues(arr);
    }
  };
  const handleIncrementLair = (q, index) => {
    console.log("increment", q, index);
    let arr = [...valuesLair];
    let cartI = [...secretItems];
    if (q > arr[index]) {
      arr[index] = arr[index] + 1;
      let qt = cartI[index];
      qt = { ...qt, cantidad: valuesLair[index] + 1 };
      cartI[index] = qt;
      console.log("before dispatch", cartI, qt);
      localStorage.setItem("secretItem", JSON.stringify(cartI));
      dispatch(setSecretItem(cartI));
      let t = 0;
      cartI.map((d) => (t = t + d.cantidad * d.precio));
      setTotal(t);
      setValuesLair(arr);
    }
  };
  const handleDelete = (index) => {
    let cartI = [...cartItems];
    cartI = cartI.filter((d, i) => i != index);
    localStorage.setItem("cartItem", JSON.stringify(cartI));

    dispatch(setCartItem(cartI));
    let t = 0;
    cartI.map((d) => (t = t + d.quantity * d.precio));
    setTotal(t);
    setValues(cartI?.map((d) => d.quantity));
  };
  const handleDeleteSer = (index) => {
    let cartI = [...secretItems];
    cartI = cartI.filter((d, i) => i != index);
    localStorage.setItem("secretItem", JSON.stringify(cartI));

    dispatch(setSecretItem(cartI));
    let t = 0;
    cartI.map((d) => (t = t + d.quantity * d.precio));
    setTotal(t);
    setValues(cartI?.map((d) => d.quantity));
  };
  useEffect(() => {
    let t = 0;
    let ts = 0;

    cartItems?.map((d) => (t = t + d.quantity * d.precio));
    secretItems?.map((d) => (ts = ts + d.cantidad * d.precio));
    setTotal(t);
    setTotalSl(ts);
    setValues(cartItems?.map((d) => d.quantity));
    setValuesLair(secretItems?.map((d) => d.cantidad));
  }, [cartItems]);
  function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str)
      .replace(/[!'()]/g, escape)
      .replace(/\*/g, `%27`);
  }
  const generateCardLink = (card) => {
    // Split the card name into two parts using " // "
    const cardNameParts = card?.nombre?.split(" // ");

    // Encode each part of the card name

    const identifier =
      cardNameParts.length > 1 ? "multiple-parts" : "single-part";

    // Combine the encoded parts, identifier, set name, and collector number into the final link

    let cardLink = null;
    if (cardNameParts?.length > 1) {
      cardLink =
        "/cardview/" +
        fixedEncodeURIComponent(card?.set.toLowerCase()) +
        "/" +
        fixedEncodeURIComponent(card?.nombre.toLowerCase()) +
        "/" +
        (card?.collector_number?.length > 0
          ? card?.collector_number
          : "default") +
        "/" +
        identifier;
      console.log(cardLink?.split("/")[5]);
    } else {
      cardLink =
        "/cardview/" +
        fixedEncodeURIComponent(card?.set.toLowerCase()) +
        "/" +
        fixedEncodeURIComponent(card?.nombre.toLowerCase()) +
        "/" +
        (card?.collector_number?.length > 0
          ? card?.collector_number
          : "default") +
        "/" +
        identifier;
    }
    return cardLink;
  };
  useEffect(() => {
    if (userI) {
      serverInstance
        .post("/user/getCredit", {
          id: userI?.iduser,
        })
        .then((data) => {
          console.log(data, data.credito);
          setCredito(data?.data.credito);
        })
        .catch((err) => {
          console.log(err);
        });
      serverInstance
        .post("/user/check_order", {
          id: userI?.iduser,
        })
        .then((data) => {
          console.log(data, data.credito);
          setCan(data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userI]);

  //   success: (result) =>{
  //     console.log(result.can);
  //     if (result.can){
  //         $('#extraButton').append(`<form action="addpedido" method="POST">
  //     <input type="hidden" name="idorder" value="` + result.idorder + `">
  //     <button class="checkoutbutton" style="width: 142px;" type="submit">Añadir</button>
  //     </br>
  //     <strong style="font-size: 95%;">Tienes el pedido (` + result.idorder + `) pendiente de pago, puedes agregar las cartas a ese pedido</strong>
  //     </form>`);
  //     } else {
  //         $('#extraButton').append(`
  //     <button class="checkoutbutton" style="width: 142px;" type="submit" disabled>Añadir</button>
  //     </br>
  //     <strong style="font-size: 25px;">Si tienes un pedido pendiente de pago, puedes agregarle más cartas haciendo un nuevo pedido</strong>
  //     `);
  //     }
  // },
  useEffect(() => {
    let sub = selectedOption === "option1" ? Number(credito + 4000) : credito;
    let t = total - sub < 0 ? 0 : total - sub;
    setFinal(t);
  }, [selectedOption, creditoo]);

  return (
    <>
      <div className={styles.navBack}>
        <CatlotusNav />
      </div>
      <LowerNavbar />
      <div className="cart">
        <div className={styles.cartInner}>
          <div className={styles.top}>
            <div
              className={
                active == 1 ? styles.leftcarro : styles.leftcarroActive
              }
              onClick={() => {
                setActive(1);
              }}
            >
              Ir a carro de compras de Cartas
            </div>
            <div
              className={
                active == 2 ? styles.rightCarroActive : styles.rightCarro
              }
              onClick={() => {
                setActive(2);
              }}
            >
              Ir a carro de compras de Secret Lair
            </div>
          </div>
          {active == 1 ? (
            <div className={styles.margLower1}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}>Carta</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Set</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Estado</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Idioma</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Tratamiento foil</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Stock</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Precio</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Cantidad</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Total</div>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}></div>{" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cartItems &&
                      cartItems?.map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.cartaArea}>
                              <Link
                                to={generateCardLink(row)}
                                state={{
                                  idd: "",
                                }}
                              >
                                <div className={styles.cartAtext}>
                                  {row?.nombre}
                                </div>
                              </Link>

                              {row && row?.image_uris && (
                                <img
                                  src={
                                    row?.image_uris[0]?.small ||
                                    row?.image_uris?.small
                                  }
                                  className={styles.bander1}
                                  alt=""
                                />
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: "21px" }}>
                            {(row?.set).toUpperCase()}
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.num}>
                              {row?.estado == 0
                                ? "NM"
                                : row?.estado == 1
                                ? "SP"
                                : row?.estado == 2
                                ? "MP"
                                : row?.estado == 3
                                ? "HP"
                                : row?.estado == 4
                                ? "DMG"
                                : row?.estado == 5
                                ? "AL"
                                : ""}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.idio}>
                              {" "}
                              {(row?.idioma).toUpperCase()}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            {row?.foil >= 1 && row?.foil <= 13 ? (
                              row?.foil === 1 ? (
                                <img style={{ width: "100px" }} src={foil} />
                              ) : row?.foil === 2 ? (
                                <img style={{ width: "100px" }} src={etched} />
                              ) : row?.foil === 3 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={oversized}
                                />
                              ) : row?.foil === 4 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={oversized_foil}
                                />
                              ) : row?.foil === 5 ? (
                                <img style={{ width: "100px" }} src={gilded} />
                              ) : row?.foil === 6 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={texturized}
                                />
                              ) : row?.foil === 7 && row?.signed === 1 ? (
                                <img style={{ width: "100px" }} src={surge} />
                              ) : row?.foil === 8 ? (
                                <img style={{ width: "100px" }} src={galaxy} />
                              ) : row?.foil === 9 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={oil_slick}
                                />
                              ) : row?.foil === 10 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={step_and_compleat}
                                  alt="Step-and-Compleat"
                                />
                              ) : row?.foil === 11 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={halo}
                                  alt="Halo"
                                />
                              ) : row?.foil === 12 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={confetti}
                                  alt="Confetti"
                                />
                              ) : row?.foil === 13 ? (
                                <img
                                  style={{ width: "100px" }}
                                  src={double_rainbow}
                                  alt="Double Rainbow"
                                />
                              ) : row?.signed === 1 ? (
                                <img style={{ width: "100px" }} src={signed} />
                              ) : null
                            ) : null}
                          </TableCell>

                          <TableCell align="right" style={{ fontSize: "21px" }}>
                            {" "}
                            {row?.stock - row?.reservadas}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: "21px" }}>
                            ${(row?.precio).toLocaleString("es-CL")}
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.numberArea}>
                              <div
                                className={styles.decremet}
                                style={
                                  1 == values[index]
                                    ? { cursor: "default" }
                                    : {}
                                }
                                onClick={() =>
                                  handleDecrement(
                                    row?.stock - row?.reservadas,
                                    index
                                  )
                                }
                              >
                                -
                              </div>
                              <div className={styles.numberFielkd}>
                                {values[index]}
                              </div>
                              <div
                                className={styles.increment}
                                style={
                                  row?.stock - row?.reservadas == values[index]
                                    ? { cursor: "default" }
                                    : {}
                                }
                                onClick={() =>
                                  handleIncrement(
                                    row?.stock - row?.reservadas,
                                    index
                                  )
                                }
                              >
                                +
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: "21px" }}>
                            {" "}
                            {(row?.precio * row?.quantity).toLocaleString(
                              "es-CL"
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.cross}
                              onClick={() => handleDelete(index)}
                            >
                              <RxCross2 className={styles.crossInner} />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className={styles.margLower1}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}>Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Tipo</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Cantidad</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Precio</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Total</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}></div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {secretItems?.map((row, index) => (
                      <TableRow
                        key={row?.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          <div className={`${styles.cartaArea} `}>
                            <div className="col-md-8 col-lg-6 col-xl-6">
                              <Link
                                to={`/secretliar/${row?.id}`}
                                state={{ condi: "" }}
                              >
                                <div className={`${styles.cartAtext}  `}>
                                  {row?.nombre}
                                  <img
                                    src={
                                      BASE_URL +
                                      "/public" +
                                      "/secretlair/" +
                                      String(row?.foto)
                                    }
                                    className={styles.bander1}
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "21px" }}>
                          {row?.tipoo}
                        </TableCell>

                        <TableCell align="right" style={{ fontSize: "21px" }}>
                          <div className={styles.numberArea}>
                            <div
                              className={styles.decremet}
                              style={
                                1 == valuesLair[index]
                                  ? { cursor: "default" }
                                  : {}
                              }
                              onClick={() =>
                                handleDecrementLair(row?.cantidad, index)
                              }
                            >
                              -
                            </div>
                            <div className={styles.numberFielkd}>
                              {valuesLair[index]}
                            </div>
                            <div
                              className={styles.increment}
                              // style={
                              //   row?.cantidad == valuesLair[index]
                              //     ? { cursor: "default" }
                              //     : {}
                              // }
                              onClick={() =>
                                handleIncrementLair(row?.cantidad + 1, index)
                              }
                            >
                              +
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "21px" }}>
                          ${(row?.precio).toLocaleString("es-CL")}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "21px" }}>
                          {" "}
                          {(row?.precio * row?.cantidad).toLocaleString(
                            "es-CL"
                          )}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "21px" }}>
                          <div
                            className={styles.cross}
                            onClick={() => handleDeleteSer(index)}
                            style={{ paddingTop: "2px" }}
                          >
                            <RxCross2 className={styles.crossInner} />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {cartItems?.length > 0 && active == 1 ? (
            <div className="d-flex justify-content-between">
              <div>
                <div style={{ fontSize: "20px" }}>
                  {" "}
                  {credito && parseFloat(credito) > 0 ? (
                    <strong>
                      Tienes {` $${credito.toLocaleString("es-CL")} `} de
                      credito en tu cuenta
                    </strong>
                  ) : (
                    ""
                  )}
                </div>
                {credito && parseFloat(credito) > 0 ? (
                  <div
                    className="d-flex justify-content-end mt-1"
                    style={{ fontSize: "20px" }}
                  >
                    <strong>Usar crédito</strong>{" "}
                    <input
                      type="checkbox"
                      style={{
                        marginLeft: "10px",
                        width: "22px",
                        height: "22px",
                        marginTop: "5px",
                      }}
                      onChange={(e) => {
                        dispatch(setCredit(e.target.checked));
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.pricArea} style={{ maxWidth: "60%" }}>
                <div
                  className={styles.totalPargo}
                  style={{ textAlign: "end", fontSize: "21px" }}
                >
                  Subtotal:{` `}
                  <span>${Number(total).toLocaleString("es-CL")}</span>
                  {credito && parseFloat(credito) > 0 ? (
                    <span style={{ marginLeft: "15px" }}>
                      Crédito: {` $${Number(credito).toLocaleString("es-CL")} `}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.totalPargo} style={{ textAlign: "end" }}>
                  Total a pagar:{" "}
                  {creditoo ? (
                    <span className={styles.dollar}>
                      {console.log(Number(final))}
                      {console.log(
                        Number(
                          (selectedOption === "option1"
                            ? Number(total + 4000)
                            : total) -
                            credito <
                            0
                            ? 0
                            : (selectedOption === "option1"
                                ? Number(total + 4000)
                                : total) - credito
                        )
                      )}
                      $
                      {Number(
                        (selectedOption === "option1"
                          ? Number(total + 4000)
                          : total) -
                          credito <
                          0
                          ? 0
                          : (selectedOption === "option1"
                              ? Number(total + 4000)
                              : total) - credito
                      ).toLocaleString("es-CL")}
                    </span>
                  ) : (
                    <span className={styles.dollar}>
                      $
                      {Number(
                        selectedOption === "option1"
                          ? Number(total + 4000)
                          : total
                      ).toLocaleString("es-CL")}
                    </span>
                  )}
                </div>
                {total <= 15000 ? (
                  <div
                    className="mb-2 "
                    style={{ textAlign: "end", fontWeight: "bold" }}
                  >
                    El pedido mínimo debe ser de $15.000
                  </div>
                ) : (
                  ""
                )}
                <label className={styles.al}>
                  <input
                    type="radio"
                    name="group"
                    value="option1"
                    checked={selectedOption === "option1"}
                    onChange={handleOptionChange}
                  />
                  <span className={styles.er}> Envio (+$4000)</span>
                </label>
                <label style={{ display: "flex", justifyContent: "end" }}>
                  <div
                    className={styles.lty}
                    style={{ display: "flex", justifyContent: "start" }}
                  >
                    <input
                      type="radio"
                      name="group"
                      value="option2"
                      checked={selectedOption === "option2"}
                      onChange={handleOptionChange}
                    />
                    <span
                      style={{
                        fontSize: "20px",
                        color: "#212529",
                        fontWeight: "400",
                        marginLeft: "15px",
                      }}
                    >
                      {" "}
                      Retiro (+$0)
                    </span>
                  </div>
                </label>
                <div style={{ textAlign: "end" }}>
                  Retiro en tienda Piedra Bruja en metro Manuel Montt
                </div>

                <button
                  className={styles.comprar}
                  disabled={
                    selectedOption === "" || total <= 15000 ? true : false
                  }
                  style={
                    selectedOption === "" || total <= 15000
                      ? {
                          background: "#b9b4a6",
                          color: "#000",
                          marginBottom: "30px",
                        }
                      : { marginBottom: "30px" }
                  }
                  onClick={() => {
                    if (total <= 15000) {
                      return Swal.fire(
                        "Order should be greater then or equal 15000",
                        "",
                        "error"
                      );
                    }
                    navigate(
                      userI
                        ? `/DeliveryInformation?retiro=${
                            selectedOption === "option2" ? true : false
                          }`
                        : "/login"
                    );
                  }}
                >
                  Comprar
                </button>

                {can?.can == true ? (
                  <div className="d-flex gap-3 justify-content-between align-content-center flex-wrap">
                    <strong style={{ fontSize: "95%", maxWidth: "300px" }}>
                      Tienes el pedido {`  ${can?.idorder}  `} pendiente de
                      pago, puedes agregar las cartas a ese pedido
                    </strong>{" "}
                    <button
                      onClick={() => {
                        serverInstance
                          .post("/user/addpedido", {
                            id: can?.idorder,
                            cart: cartItems,
                            userID: userI?.iduser,
                          })
                          .then((res) => {
                            dispatch(setCartItem([]));
                            navigate("/order");
                          })
                          .catch((err) => {
                            console.log(err);
                            Swal.fire("Something went wrong", "", "error");
                          });
                      }}
                      className={styles.comprar}
                    >
                      Añadir
                    </button>
                  </div>
                ) : (
                  <div className="d-flex gap-3 justify-content-between align-content-center flex-wrap">
                    <strong style={{ fontSize: "25px", maxWidth: "300px" }}>
                      Si tienes un pedido pendiente de pago, puedes agregarle
                      más cartas haciendo un nuevo pedido
                    </strong>{" "}
                    <button
                      style={
                        can?.can == false
                          ? { background: "#a37e11", color: "white" }
                          : {}
                      }
                      disabled={can?.can == false ? true : false}
                      className={styles.comprar}
                    >
                      Añadir
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {secretItems?.length > 0 && active == 2 ? (
            <>
              <div className={styles.pricArea}>
                Tienes $` {credito} ` de credito en tu cuenta
                <div className={styles.totalPargo}>
                  Total a pagar:{" "}
                  <span className={styles.dollar}>
                    ${Number(totalSl).toLocaleString("es-CL")}
                  </span>
                </div>
                <div>Retiro en tienda Piedra Bruja en metro Manuel Montt</div>
                <Link
                  to={
                    userI
                      ? `/DeliveryInformation_sl?retiro=${
                          selectedOption === "option2" ? true : false
                        }`
                      : "/login"
                  }
                >
                  <button className={styles.comprar}>Comprar</button>
                </Link>
              </div>
              {/* <div
                style={{
                  textAlign: "right",
                  fontWeight: "700",
                  color: "#212529",
                  fontSize: "20px",
                }}
              >
                If you have an order pending payment, you can add more letters
                to it by placing a new order
              </div> */}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart2;
