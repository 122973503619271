import React, { useState } from "react";
import Footer from "../Footer/Footer";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "./AdminSolicitudes.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { serverInstance } from "../../axiosInstance";
import { useEffect } from "react";
import UserInfoModal from "./UserInfoModal";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
const Comunas = [
  "Cerrillos",
  "Cerro Navia",
  "Colina",
  "Conchalí",
  "El Bosque",
  "Estación Central",
  "Huechuraba",
  "Independencia",
  "La Cisterna",
  "La Florida",
  "La Granja",
  "La Pintana",
  "La Reina",
  "Las Condes",
  "Lo Barnechea",
  "Lo Espejo",
  "Lo Prado",
  "Macul",
  "Maipú",
  "María Pinto",
  "Ñuñoa",
  "Padre Hurtado",
  "Peñaflor",
  "Peñalolén",
  "Pirque",
  "Providencia",
  "Pudahuel",
  "Puente Alto",
  "Quilicura",
  "Quinta Normal",
  "Recoleta",
  "Renca",
  "San Bernardo",
  "San Joaquín",
  "San Miguel",
  "San Pedro",
  "San Ramón",
  "Santiago",
  "Vitacura",
];
function PopupModal({
  showModal,
  handleCloseModal,
  handleSubmit,
  textAreaValue,
  handleTextAreaChange,
}) {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTextarea">
            <Form.Label>notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
const AdminSolicitudes = () => {
  const [tabchange, setTabchange] = useState(1);
  const [first, setFirst] = useState([]);
  const [two, setTwo] = useState([]);
  const [three, setThree] = useState([]);
  const [four, setFour] = useState([]);
  const [five, setFive] = useState([]);
  const [blue, setBlue] = useState();
  const [srep, setSrep] = useState();
  const [orderId, setOrderId] = useState();
  const [detailOne, setDetailOne] = useState();
  const [showModal1, setShowModal1] = useState(false);
  const openModal = () => setShowModal1(true);
  const closeModal = () => setShowModal1(false);
  const [showModal, setShowModal] = useState(false);

  const [textAreaValue, setTextAreaValue] = useState("");
  const [inttValues, setInttValues] = useState(Array(three?.length).fill(""));
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTextAreaValue("");
  };

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
  };
  const handleInputChange = (itemId, value) => {
    // Update the 'intt' value for the selected item
    const updatedInttValues = [...inttValues];
    updatedInttValues[itemId] = value;
    setInttValues(updatedInttValues);
  };
  const handleSubmit = () => {
    console.log("Textarea Value:", textAreaValue);
    serverInstance
      .post("/user/updatenotes", {
        nota: textAreaValue,
        id: detailOne.id,
      })
      .then((res) => {
        // Swal.fire("Added succesfully", "", "success");
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });

    handleCloseModal();
  };
  const handleSubmity = (id, index) => {
    if (inttValues[index] == "") {
      return Swal.fire("Enter value", "", "error");
    }
    Swal.fire({
      text: "Se está actualizando el numero de seguimiento",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .post("/user/changeNroSeguimiento", {
            nroSeguimiento: inttValues[index],
            id: id,
          })
          .then((res) => {
            // handleInputChange(index, 0);
            // Swal.fire("Added succesfully", "", "success");
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
    handleCloseModal();
  };
  useEffect(() => {
    One();
  }, []);
  const One = () => {
    serverInstance
      .get("/user/solicitudes/0")
      .then((res) => {
        setFirst(res?.data?.resultado?.result);
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }

        if (res?.data?.resultado?.blue_mails !== undefined) {
          console.log(res?.data?.resultado?.blue_mails, "blue_mails if");
          for (var i = 0; i < res?.data?.resultado?.blue_mails.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Two = () => {
    serverInstance
      .get("/user/solicitudes/1")
      .then((res) => {
        setTwo(res?.data?.resultado?.result);
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }
        if (res?.data?.resultado?.blue_mails !== undefined) {
          for (var i = 0; i < res?.data?.resultado?.blue_mails?.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
        console.log(blueMails, listRepEmails);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Three = () => {
    serverInstance
      .get("/user/solicitudes/2")
      .then((res) => {
        setThree(res?.data?.resultado?.result);
        setInttValues(
          res?.data?.resultado?.result?.map((d) => d?.numero_seguimiento)
        );
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }
        if (res?.data?.resultado?.blue_mails !== undefined) {
          for (var i = 0; i < res?.data?.resultado?.blue_mails?.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
        console.log(blueMails, listRepEmails);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Four = () => {
    serverInstance
      .get("/user/solicitudes/encargadas")
      .then((res) => {
        setFour(res?.data?.resultado);
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Five = () => {
    serverInstance
      .get("/user/solicitudes/solicitadas")
      .then((res) => {
        setFive(res?.data?.resultado);
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const confirmOne = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setConfirmada/${id}`)
          .then((res) => {
            One();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const empphaTwo = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setEmpacada/${id}`)
          .then((res) => {
            Two();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const confirmThree = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setEnviada/${id}`)
          .then((res) => {
            Three();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const cancelOne = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setRechazada/${id}`)
          .then((res) => {
            One();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const addressOne = (e, id) => {
    e.preventDefault();
    serverInstance
      .get(`/user/solicitudes/${id}`)
      .then((res) => {
        // One();
        console.log(res);
        // setDetailOne(res.data.resultado.)
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const tabHandle = () => {
    setTabchange(1);
    One();
  };
  const tabHandle1 = () => {
    setTabchange(2);
    Two();
  };
  const tabHandle2 = () => {
    setTabchange(3);
    Three();
  };
  const tabHandle3 = () => {
    setTabchange(4);
    Four();
  };
  const tabHandle4 = () => {
    setTabchange(5);
    Five();
  };
  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }

    // Assuming the date is in the format "YYYY-MM-DD"
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const year = parts[0].slice(-2); // Get the last two digits of the year
      const month = parts[1];
      const day = parts[2];
      return `${day}-${month}-${year}`;
    } else {
      return "Invalid Date";
    }
  }
  return (
    <>
      <PopupModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
        textAreaValue={textAreaValue}
        handleTextAreaChange={handleTextAreaChange}
      />
      <UserInfoModal show={showModal1} onClose={closeModal} data={detailOne} />
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.order}>
        <div className={styles.orderInner}>
          <div className={styles.topButons}>
            <button
              className={styles.secondbutton}
              onClick={tabHandle}
              style={
                tabchange == 1 ? { color: "white", background: "#000" } : {}
              }
            >
              Compras pendientes
            </button>
            <button
              className={styles.secondbutton}
              onClick={tabHandle1}
              style={
                tabchange == 2 ? { color: "white", background: "#000" } : {}
              }
            >
              Compras confirmadas
            </button>
            <button
              className={styles.secondbutton}
              onClick={tabHandle2}
              style={
                tabchange == 3 ? { color: "white", background: "#000" } : {}
              }
            >
              Compras empacadas
            </button>
            <button
              className={styles.secondbutton}
              onClick={tabHandle3}
              style={
                tabchange == 4 ? { color: "white", background: "#000" } : {}
              }
            >
              Cartas encargadas
            </button>
            <button
              className={styles.secondbutton}
              onClick={tabHandle4}
              style={
                tabchange == 5 ? { color: "white", background: "#000" } : {}
              }
            >
              Cartas reservadas
            </button>
          </div>
          <div className={styles.searchArea}>
            <div className={styles.textSaecr}>Search Orders</div>
            <input
              type="text"
              className={styles.serInput}
              value={orderId}
              onChange={(e) => {
                setOrderId(e.target.value);
              }}
            />
          </div>
          <div className={styles.lowerTable}>
            {tabchange === 1 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> ID</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Apellido</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Email</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Monto</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Credito</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Fecha de compra</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Confirmar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Cancelar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Dirección</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Detalle</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {first
                      ?.filter((row) =>
                        orderId
                          ? String(row?.id)?.includes(String(orderId))
                          : true
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              ${(row?.monto || 0).toLocaleString("es-CL")}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              ${row?.credito}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {formatDate(row?.fecha_compra)}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                confirmOne(e, row?.id);
                              }}
                            >
                              Confirmar
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                cancelOne(e, row?.id);
                              }}
                            >
                              Cancelar
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={`/OrderDetail/${row?.id}`}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tabchange === 2 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> ID</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Apellido</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Email</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Monto</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Credito</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Fecha de compra</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Empacar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Direccion</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Detalles </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Notas</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {two
                      ?.filter((row) =>
                        orderId
                          ? String(row?.id)?.includes(String(orderId))
                          : true
                      )
                      ?.map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              ${(row?.monto || 0).toLocaleString("es-CL")}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              ${row?.credito}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {formatDate(row?.fecha_compra)}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                empphaTwo(e, row?.id);
                              }}
                            >
                              Empacar
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                                to={`/OrderDetail/${row?.id}`}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.detl}
                              onClick={() => {
                                setDetailOne(row);
                                handleOpenModal();
                                setTextAreaValue(row?.notas);
                              }}
                              style={
                                row?.notas
                                  ? { background: "red", color: "white" }
                                  : {}
                              }
                            >
                              Ver
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tabchange === 3 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> ID</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Apellido</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Email</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Monto</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Credito</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Fecha de compra</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Enviar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>
                          {" "}
                          Número de seguimiento
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Dirección</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Detalle</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Notas</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {three
                      ?.filter((row) =>
                        orderId
                          ? String(row?.id)?.includes(String(orderId))
                          : true
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              ${(row?.monto || 0).toLocaleString("es-CL")}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              ${row?.credito}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {formatDate(row?.fecha_compra)}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                confirmThree(e, row?.id);
                              }}
                            >
                              Confirmar
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.confi1}>
                              <input
                                type="number"
                                placeholder="0"
                                className={styles.inCam}
                                value={inttValues[index]}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                                disabled={row?.retiro ==1}
                              />
                              <button
                                style={{ marginTop: "5px" }}
                                onClick={(e) => {
                                  handleSubmity(row?.id, index);
                                }}
                                className={styles.dffdf}
                              >
                                Cambiar
                              </button>
                              {inttValues[index] === 0 && row?.retiro ==0 && Comunas?.includes(row?.comuna) &&  (
                                <div style={{ color: "red" }}>NS*</div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                               to={`/OrderDetail/${row?.id}`}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.detl}
                              onClick={() => {
                                setDetailOne(row);
                                handleOpenModal();
                                setTextAreaValue(row?.notas);
                              }}
                              style={
                                row?.notas
                                  ? { background: "red", color: "white" }
                                  : {}
                              }
                            >
                              Ver
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tabchange === 4 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> Cantidad</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Set</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Idioma</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Estado</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Foil</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Signed</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>Colector Number </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {four?.map((row, index) => (
                      <TableRow
                        key={row?.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {row?.cantidad == "1" ? "" : row?.cantidad}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>{row?.nombre}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>{row?.set}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {row?.idioma.toLowerCase() == "eng"
                              ? ""
                              : row?.idioma}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {console.log(typeof row?.estado, row?.estado)}
                            {row?.estado == "0" && ""}
                            {row?.estado == "1" && "SP"}
                            {row?.estado == "2" && "MP"}
                            {row?.estado == "3" && "HP"}
                            {row?.estado == "4" && "DMG"}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {row?.signed === 1
                              ? ""
                              : (row?.foil === 1 && "Foil") ||
                                (row?.foil === 2 && "Etched") ||
                                (row?.foil === 3 && "Oversized") ||
                                (row?.foil === 4 && "Oversized Foil") ||
                                (row?.foil === 5 && "Gilded") ||
                                (row?.foil === 6 && "Texturized") ||
                                (row?.foil === 7 && "Surge") ||
                                (row?.foil === 8 && "Galaxy") ||
                                (row?.foil === 9 && "Oil Slick")}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {" "}
                            {row?.signed == "0" && ""}{" "}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {row?.collector_number}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tabchange === 5 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Set</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Idioma</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Reservadas</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Foil</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Signed</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Colector Number</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {five?.map((row, index) => (
                      <TableRow
                        key={row?.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          <div className={styles.tabData}>{row?.nombre}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>{row?.set}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>{row?.idioma}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {row?.reservadas}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {row?.signed === 1
                              ? ""
                              : (row?.foil === 1 && "Foil") ||
                                (row?.foil === 2 && "Etched") ||
                                (row?.foil === 3 && "Oversized") ||
                                (row?.foil === 4 && "Oversized Foil") ||
                                (row?.foil === 5 && "Gilded") ||
                                (row?.foil === 6 && "Texturized") ||
                                (row?.foil === 7 && "Surge") ||
                                (row?.foil === 8 && "Galaxy") ||
                                (row?.foil === 9 && "Oil Slick")}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {row?.signed == "0" && ""}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tabData}>
                            {row?.collector_number}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminSolicitudes;
