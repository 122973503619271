import React, { useState, useEffect } from "react";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "./Home.module.css";
import lair from "./HomeImages/SecretLiar.png";
import basket from "./HomeImages/basket.png";
import comput from "./HomeImages/computer.png";
import comflo from "./HomeImages/comflo.png";
import comflo1 from "./HomeImages/comflo1.png";
import cato from "./HomeImages/cato.png";
import img1 from "../Home/HomeImages/img1.png";
import img2 from "../Home/HomeImages/img2.png";
import img3 from "../Home/HomeImages/img3.png";
import img4 from "../Home/HomeImages/img4.png";
import img5 from "../Home/HomeImages/img5.png";
import Footer from "../Footer/Footer";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import { serverInstance } from "../../axiosInstance";
import { Link } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, "%2A");
}
const Home = () => {
  const [bob, setBob] = useState([]);
  const [lcu, setLcu] = useState([]);
  const [ms, setMs] = useState([]);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    scrollToTop();
  }, []);
  useEffect(() => {
    serverInstance
      .get("/cart/get5randomcards")
      .then((res) => {
        console.log(res.data);
        let cards = res.data;
        let bobb = [];
        for (let i = 0; i < cards.length; i++) {
          let link = null;
          let image = null;
          let name = null;

          image = cards[i].image_uris.small
            ? cards[i].image_uris.small
            : cards[i]?.card_faces[0]?.image_uris?.small;
          name = cards[i].name;
          link = generateCardLink(cards[i]);
          // link =
          //   "/cardview/" +
          //   fixedEncodeURIComponent(cards[i].set.toLowerCase()) +
          //   "/" +
          //   fixedEncodeURIComponent(cards[i].name.toLowerCase()) +
          //   "/" +
          //   (cards[i].collector_number.length > 0
          //     ? cards[i].collector_number
          //     : "default");
          bobb.push({
            name: name,
            image: image,
            link: link,
          });
        }
        setBob(bobb);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const generateCardLink = (card) => {
    // Split the card name into two parts using " // "
    const cardNameParts = card?.name.split(" // ");

    // Encode each part of the card name

    const identifier =
      cardNameParts.length > 1 ? "multiple-parts" : "single-part";

    // Combine the encoded parts, identifier, set name, and collector number into the final link

    let cardLink = null;
    if (cardNameParts.length > 1) {
      cardLink =
        "/cardview/" +
        fixedEncodeURIComponent(card?.set.toLowerCase()) +
        "/" +
        fixedEncodeURIComponent(card?.name.toLowerCase()) +
        "/" +
        (card?.collector_number.length > 0
          ? card?.collector_number
          : "default") +
        "/" +
        identifier;
      console.log(cardLink?.split("/")[5]);
    } else {
      cardLink =
        "/cardview/" +
        fixedEncodeURIComponent(card?.set.toLowerCase()) +
        "/" +
        fixedEncodeURIComponent(card?.name.toLowerCase()) +
        "/" +
        (card?.collector_number.length > 0
          ? card?.collector_number
          : "default") +
        "/" +
        identifier;
    }
    return cardLink;
  };
  useEffect(() => {
    serverInstance
      .get("/cart/lastuploads")
      .then((res) => {
        console.log(res?.data);
        let cards = res?.data;
        let bobb = [];
        for (let i = 0; i < cards?.length; i++) {
          let link = null;
          let image = null;
          let name = null;

          image = cards[i]?.image_uris?.small
            ? cards[i]?.image_uris?.small
            : cards[i]?.card_faces[0]?.image_uris?.small;
          name = cards[i]?.name;
          link = generateCardLink(cards[i]);
          // "/cardview/" +
          // fixedEncodeURIComponent(cards[i]?.set.toLowerCase()) +
          // "/" +
          // fixedEncodeURIComponent(cards[i]?.name.toLowerCase()) +
          // "/" +
          // (cards[i]?.collector_number?.length > 0
          //   ? cards[i]?.collector_number
          //   : "default");
          bobb.push({
            name: name,
            image: image,
            link: link,
          });
        }
        setLcu(bobb);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    serverInstance
      .get("/cart/bestsellers")
      .then((res) => {
        console.log(res?.data);
        let cards = res.data;
        let bobb = [];
        for (let i = 0; i < cards.length; i++) {
          let link = null;
          let image = null;
          let name = null;

          image = cards[i]?.image_uris?.small
            ? cards[i]?.image_uris?.small
            : cards[i]?.card_faces[0]?.image_uris?.small;
          name = cards[i]?.name;
          link = generateCardLink(cards[i]);
          // "/cardview/" +
          // fixedEncodeURIComponent(cards[i].set.toLowerCase()) +
          // "/" +
          // fixedEncodeURIComponent(cards[i].name.toLowerCase()) +
          // "/" +
          // (cards[i].collector_number.length > 0
          //   ? cards[i].collector_number
          //   : "default");
          bobb.push({
            name: name,
            image: image,
            link: link,
          });
        }
        setMs(bobb);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div>
        <div className={styles.navBack}>
          <CatlotusNav />
        </div>
        <LowerNavbar />
        <div className={styles.venidos}>
          <div className={styles.centervanidos}>
            <div className={styles.computerImageArea}>
              <Link to="/search">
                <img src={comput} className={styles.comput} alt="" />
                <img src={comflo} className={styles.comflo} alt="" />
                <div className={styles.comprara}>Buscar cartas</div>
              </Link>
            </div>
            <div className={styles.catRea}>
              <div className={styles.bien}>¡Bienvenidos!</div>
              <div className={styles.nuestra}>
                A nuestra nueva página web, <br /> estamos trabajando en nuevas
                novedades para ustedes
              </div>
              <img src={cato} alt="" className={styles.cato} />
            </div>
          </div>
        </div>
        <div className={styles.mejorARea}>
          <div className={styles.mejorHeading}>Lo mejor de lo mejor</div>
          <div className={styles.cardsArea}>
            {bob.length > 0 ? (
              bob.map((item) => (
                <div className={styles.card1}>
                  <Link
                    to={item.link}
                    state={{
                      idd:
                        item?.link?.split("/")[5] == "multiple-parts"
                          ? item.name
                          : "",
                    }}
                  >
                    <img src={item.image} className={styles.cardImag} alt="" />{" "}
                    <div className={styles.cardText}>{item.name}</div>
                  </Link>
                </div>
              ))
            ) : (
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}
          </div>
        </div>
        <div className={styles.ultemoARea}>
          <div className={styles.mejorHeading}>Lo último que nos llegó</div>
          <div className={styles.cardsArea}>
            {lcu.length > 0 ? (
              lcu.map((item) => (
                <div className={styles.card1}>
                  <Link
                    to={item.link}
                    state={{
                      idd:
                        item?.link?.split("/")[5] == "multiple-parts"
                          ? item.name
                          : "",
                    }}
                  >
                    {" "}
                    <img src={item.image} className={styles.cardImag} alt="" />
                    <div className={styles.cardText}>{item.name}</div>
                  </Link>
                </div>
              ))
            ) : (
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}
          </div>
        </div>
        <div className={styles.masARea}>
          <div className={styles.mejorHeading}>Lo más vendido</div>
          <div className={styles.cardsArea}>
            {ms.length > 0 ? (
              ms.map((item) => (
                <div className={styles.card1}>
                  <Link
                    to={item.link}
                    state={{
                      idd:
                        item?.link?.split("/")[5] == "multiple-parts"
                          ? item.name
                          : "",
                    }}
                  >
                    {" "}
                    <img src={item.image} className={styles.cardImag} alt="" />
                    <div className={styles.cardText}>{item.name}</div>
                  </Link>
                </div>
              ))
            ) : (
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
